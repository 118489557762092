import ProductFeatureType, {ProductFeatureTypeEnum} from "@/models/ProductFeatureType";
import {Vue} from "vue-property-decorator";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";

export default class ProductFeatureTypeService {


    static typeToName(type: ProductFeatureTypeEnum) {
        switch (type) {
            case ProductFeatureTypeEnum.BOOLEAN: return "Boleano"
            case ProductFeatureTypeEnum.FLOAT: return "Decimal"
            case ProductFeatureTypeEnum.INTEGER: return "Entero"
            case ProductFeatureTypeEnum.STRING: return "Cadena de caracteres"
            case ProductFeatureTypeEnum.VALUES: return "Otros valores"
        }
    }

    static getProductFeatureTypes(component: Vue, productFeatureTypes: ProductFeatureType[]) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/product-feature-types", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, ProductFeatureType)
                productFeatureTypes.splice(0, productFeatureTypes.length)
                list.forEach(pf => productFeatureTypes.push(pf))
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener las características"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getProductFeatureType(component: Vue, productFeatureTypeId: number) {
        (<any>component).loading = true
        component.axios.get(ConstantTool.BASE_URL + "/public/product-feature-types/" + productFeatureTypeId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                (<any>component).productFeatureType = JsonTool.jsonConvert.deserializeObject(response.data, ProductFeatureType)
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener la característica"))
            .finally(() => (<any>component).loading = false)
    }

    static postProductFeatureType(component: Vue, productFeatureType: ProductFeatureType) {
        (<any>component).loading = true
        component.axios.post(ConstantTool.BASE_URL + "/api/product-feature-types",
            productFeatureType, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let item = JsonTool.jsonConvert.deserializeObject(response.data, ProductFeatureType)
                component.$router.push({path: "/product-feature-types/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear la característica"))
            .finally(() => (<any>component).loading = false)
    }

    static patchProductFeatureType(component: Vue, productFeatureTypeId: number, productFeatureType: ProductFeatureType) {
        (<any>component).loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/product-feature-types/" + productFeatureTypeId,
            productFeatureType, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(() => (<any>component).refresh())
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar la característica"))
            .finally(() => (<any>component).loading = false)
    }

}
